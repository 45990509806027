<template lang="">
  <div>
    <div class="banner">
      <div class="container">
        <el-row :gutter="20" class="content-head">
          <el-col :span="24">
            <router-link class="linkTo" to="/blogs"><p style="margin-top: 100px; color: #3370FF">{{ $t(`Layout['blogs']`) }}/</p></router-link>
            <h1>{{ $t(`Blogs['mosAppCallingFriends']`) }}</h1>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="container" style="margin-top: 30px; margin-bottom: 50px;">
      <div style="display:flex; justify-content:space-between">
        <h1>May, 4, 2023</h1>
        <div class="container-socail">
          <!-- <p>follow us</p>
          <div class="box-socail">
            <img src="../../assets/image/home/twitter.png" alt="" />
            <img src="../../assets/image/home/facebook.png" alt="" />
            <img src="../../assets/image/home/linkedin.png" alt="" />
          </div> -->
        </div>
      </div>
      <div class="blog-detail">
        <h1>{{ $t(`Blogs['introduction']`) }}:</h1>
        <p>{{ $t(`Blogs['mosAppInterconnected']`) }}</p>
        <h1>1). {{ $t(`Blogs['mosAppCallTitle1']`) }} </h1>
        <p>{{ $t(`Blogs['mosAppCallDesc1']`) }}</p>
        <h1>2). {{ $t(`Blogs['mosAppCallTitle2']`) }} </h1>
        <p>{{ $t(`Blogs['mosAppCallDesc2']`) }}</p>
        <img src="../../assets/image/cards/Blog Detail3.png" alt="" />
        <h1>3). {{ $t(`Blogs['mosAppCallTitle3']`) }} </h1>
        <p>{{ $t(`Blogs['mosAppCallDesc3']`) }}</p>
        <h1>4). {{ $t(`Blogs['mosAppCallTitle4']`) }} </h1>
        <p>{{ $t(`Blogs['mosAppCallDesc4']`) }}</p>
        <h1>5) {{ $t(`Blogs['mosAppCallTitle5']`) }} </h1>
        <p>{{ $t(`Blogs['mosAppCallDesc5']`) }}</p>
        <h1>6). {{ $t(`Blogs['mosAppCallTitle6']`) }} </h1>
        <p>{{ $t(`Blogs['mosAppCallDesc6']`) }}</p>
        <h1>{{ $t(`Blogs['mosAppCallTitle7']`) }}</h1>
        <p>{{ $t(`Blogs['mosAppCallDesc7']`) }}</p>
        <p>{{ $t(`Blogs['mosAppCallDesc8']`) }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      formLabelWidth: '120px',
      fileList: [
        { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
      ]
    }
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`)
    },
    beforeRemove(file) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`)
    }
  }
}
</script>
<style lang="less" scoped>
.banner {
  position: relative;
  background-color: #E7EEFF99;
  background-position: center;
  height: auto;
  .container {
    color: #ffffff;
    position: relative;
    .content-head {
      padding-bottom: 40px;
      h1 {
        color: #1F1F1F;
        font-size: 50px;
        font-weight: 700;
      }
      p {
        font-size: 24px;
        font-weight: 400px;
      }
    }
  }
}
.container-socail {
  display: flex;
  align-items: center;
  column-gap: 20px;
  .box-socail img {
    margin-left: 15px;
    width: 30px;
  }
}
.blog-detail {
  margin-top: 30px;
  img {
    width: 60%;
    margin-bottom: 35px;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
  h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 17px;
    margin-bottom: 30px;
  }
}

</style>
